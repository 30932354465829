import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { Observable } from 'rxjs';
import { API_ENDPOINTS } from '../../core/services/api.service';
import { CommonService } from '../../shared/services/common.service';
import { HttpClient } from '@angular/common/http';
@Injectable({
  providedIn: 'root'
})
export class AuthService {
  constructor(private commonSvc: CommonService, private http: HttpClient) { }

  login(payload : any): Observable<any> {
    return this.commonSvc.httpSvc.post(environment.apiBaseUrl + API_ENDPOINTS.AUTH.LOGIN , payload);
  }
  verifyLogin(payload: { email: string | undefined; password: string | undefined; tf_otp: string }) {
    return this.commonSvc.httpSvc.post(environment.apiBaseUrl + API_ENDPOINTS.AUTH.TF_OTP, payload);
  }
  resendOtp(payload: { email: string }): Observable<any> {
    return this.commonSvc.httpSvc.post(environment.apiBaseUrl + API_ENDPOINTS.AUTH.RESEND_TF_OTP, payload)
  }
  forgotPassOtpResend(payload: { email: string }): Observable<any> {
    return this.commonSvc.httpSvc.post(environment.apiBaseUrl + API_ENDPOINTS.AUTH.RESEND_FORGET_OTP, payload)
  }
  checkUserEmailAndSendOTP(payload: { email: string }): Observable<any> {
    return this.commonSvc.httpSvc.post(environment.apiBaseUrl + API_ENDPOINTS.AUTH.VERIFY_FORGET_EMAIL, payload)
  }
  verifyForgetPassUserLogin(payload :{ email?: string; otp: string }) {
    return this.commonSvc.httpSvc.post(environment.apiBaseUrl + API_ENDPOINTS.AUTH.VERIFY_FORGET_OTP, payload)
  }
  changePassword(payload: any): Observable<any> {
    return this.commonSvc.httpSvc.put(environment.apiBaseUrl + API_ENDPOINTS.AUTH.UPDATE_PASSWORD, payload)
    //return this.http.put(`http://localhost:3000/api/user/update-password-v2`, payload);

  }
}
